.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0px;
  color: #172a6096;
  background-color: rgba(233, 233, 233, 0.378);
  text-align: right;
  padding: 1px 0;
  z-index: 1000;
}

.powered-by {
  font-size: 10px;
  font-weight: normal;
}

@media (max-width: 480px) {
  .footer {
      display: none;
}
}

@media (max-width: 768px) {
  .footer {
      display: none;
}
}
