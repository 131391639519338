.teacher-container {
  margin-top: 60px;
  margin-left: 70px;

}

.contact-container {
  padding: 0;
  margin-bottom: 60px;
}



/* Dashboard */
.teacher-dashboard-container {
  padding-left: 6%;
  padding-top: 80px;
  padding-right: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  }

  .teacher-dashboard-container-t {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .teacher-dashboard-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  }
  
  
  @media (max-width: 768px) {
    .teacher-dashboard-container-t {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      margin: 15px;
    }
  .teacher-dashboard-content,
  .additional-info {
    flex-direction: column;
    align-items: center;
  }
  
  .teacher-profile,
  .teacher-efficiency,
  .attendance-logs,
  .salary-history {
    width: 100%;
    margin-bottom: 20px;
  }

  .today-classes-t {
    width: 100% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
  }

  .teacher-header-tab{
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-bottom: 60px;
      background-color: #3292e0;
      border-radius: 0px 0px 35px 35px;
    }
  

  .teacher-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .teacher-header-info,
  .teacher-right-header {
    width: 100%;
  }

  .teacher-header-info h1 {
    font-size: 1.25rem;
  }

  .teacher-header-info h6,
  .teacher-header-info p,
  .teacher-right-header .status,
  .teacher-right-header p {
    font-size: 0.8rem;
  }

  .teacher-right-header {
    margin-top: 20px; /* Add margin to move it to the bottom */
  }
  .class-card-t {
    display: flex;
    width: 90%;
    flex-direction: column;
    background: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
  }

  .class-card-t > div {
    margin-bottom: 5px;
  }

  .class-card-t .class-card-header {
    font-weight: bold;
  }

  .schedule-button {
    width: 100%;
  }
  }
  
  @media (max-width: 480px) {
    .teacher-dashboard-container-t {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      margin: 15px;
    }
  .teacher-dashboard-content,
  .additional-info {
    flex-direction: column;
    align-items: center;
  }
  .teacher-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .teacher-header-info,
  .teacher-right-header {
    width: 100%;
  }

  .teacher-header-info h1 {
    font-size: 1.25rem;
  }

  .teacher-header-info h6,
  .teacher-header-info p,
  .teacher-right-header .status,
  .teacher-right-header p {
    font-size: 0.8rem;
  }

  .teacher-right-header {
    margin-top: 20px; /* Add margin to move it to the bottom */
  }
  .all-classes,
  .class-table {
    display: none;
  }
  
  .today-classes-t {
    width: 100% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
  }
  
  .teacher-profile,
  .teacher-efficiency,
  .today-classes-t,
  .attendance-logs,
  .salary-history {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .teacher-profile h2,
  .teacher-efficiency h2,
  .today-classes-t h2,
  .teacher-education h2,
  .attendance-logs h2,
  .salary-history h2 {
    font-size: 1.2rem;
  }
  
  .class-item .class-time,
  .class-item .class-title,
  .class-item .class-link a,
  .class-item .class-status {
    font-size: 0.9rem;
  }
  .class-card-t {
    display: flex;
    flex-direction: column;
    width: 90%;
    background: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
  }

  .class-card-t > div {
    margin-bottom: 5px;
  }

  .class-card-t .class-card-header {
    font-weight: bold;
  }

  .schedule-button {
    width: 50%;
  }
  }
  