.mycalendar-container {
  display: flex;
  flex-direction: column;

}

.calendar-main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 70px; /* Padding for top spacing */
  padding-left: 60px; /* Padding for left spacing */
}

.mycalendar-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.task-list-content {
  overflow-x: auto;
  padding: 20px;
}

@media (max-width: 1024px) {
  .calendar-main-content {
    padding-top: 50px;
    padding-left: 40px;
  }
}

@media (max-width: 768px) {
  .calendar-main-content {
    padding-top: 30px;
    padding-left: 20px;
  }

  .mycalendar-content {
    margin-top: 0;
    margin-left: 0;
  }

  .task-list-content {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .calendar-main-content {
    padding-top: 20px;
    padding-left: 10px;
  }

  .mycalendar-content {
    flex-direction: column;
  }

  .task-list-content {
    padding: 5px;
  }

  .task-list-table th,
  .task-list-table td {
    padding: 8px;
  }
}