.pos-system {
  margin: 70px 15px 50px 60px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  }
  
  /* Layout for the main POS content */
.pos-layout {
  display: flex;
  flex: 1;
  padding: 20px;
  gap: 20px;
  overflow: hidden;
}

/* Categories section styling */
.categories-section {
  flex: 1;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.categories-section h2 {
  margin-bottom: 15px;
  font-size: 18px;
}

.categories-section .ant-btn {
  width: 100%;
  margin-bottom: 10px;
}

.wrap-text {
  white-space: normal; 
  word-wrap: break-word; 
  overflow-wrap: break-word;
}

/* Menu section for displaying items */
.menu-section {
  flex: 2;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  max-height: 650px;
  overflow-y: auto;

}

.item-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
}

.item-card h3 {
  font-size: 16px;
  margin: 5px 0;
}

.item-card p {
  font-size: 14px;
  color: #888;
}

/* Cart section styling */
.cart-section {
  flex: 3;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}

.student-select {
  margin-bottom: 20px;
}

.cart-items .ant-table {
  margin-bottom: 20px;
}

.cart-summary {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subtotal, .discount, .vat, .total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.total {
  font-weight: bold;
  font-size: 16px;
}

  
  .total {
    font-weight: bold;
  }
  .vat {
    margin-top: 20px;
   margin-bottom: 20px; 
  }
  .payment {
   margin-top: 20px;
   margin-bottom: 20px; 
  }
  
  .pay-button {
    width: 100%;
    padding: 10px;
    height: 40px;
    background-color: #28a745 !important;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .sales-button {
    background-color: #1262e0 !important;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
.student-details {
  margin-top: 20px;
  background-color: #b1e2ff;
  color:#333;
  padding: 20px;
  border-radius: 10px;
  width: 93%;
}


  .sales-report {
    padding: 20px;
  }
  
  .sales-report h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sales-report .error {
    color: red;
    text-align: center;
  }
  
  .sales-report table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .sales-report table, .sales-report th, .sales-report td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .sales-report th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .sales-report tr:hover {
    background-color: #f1f1f1;
  }
  
  
  /* Responsive design */
  @media (max-width: 1200px) {
    .pos-layout {
      flex-direction: column;
    }
  
    .categories-section {
      width: 100%;
      height: auto;
    }
  
    .menu-section, .cart-section {
      width: 100%;
    }
  }


  /* Container for the inventory system layout */
.current-inventory-container {
  margin: 70px 15px 70px 80px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
  padding: 20px;
}

/* InventoryPage.css */

/* Basic styling for the inventory page layout */
.inventory-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f5f5f5;
}

.inventory-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inventory-form {
  max-width: 600px;
  margin: 0 auto;
}

.inventory-form .ant-form-item {
  margin-bottom: 20px;
}

.inventory-form .ant-btn-primary {
  width: 100%;
  background-color: #1262e0;
}

.inventory-form .ant-btn-primary:hover {
  background-color: #cde3fa;

}
