.newsletter-container {
  margin: 80px 20px 60px 70px;
  padding: 20px;
  background-color: #eff6ff; /* Changed to blue-50 */
  border-radius: 8px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.newsletter-header {
  width: 98%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1e3a8a; /* Changed to blue-900 */
  padding: 15px 20px;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.newsletter-header h1 {
  font-size: 28px;
  color: #dbeafe; /* Changed to blue-100 */
  margin-bottom: 10px;
}

.newsletter-content {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  gap: 20px;
}

.newsletter-left {
  flex: 1;
}

.newsletter-right {
  flex: 0 0 300px;
  justify-content: center;
  align-items: center;
}

.newsletter-right img {
  max-width: 100%;
  max-height: 475px;
  width: 300px;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid #bfdbfe; /* Added border with blue-200 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-placeholder {
  width: 300px;
  height: 475px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #bfdbfe; /* Changed to blue-200 */
  color: #3b82f6; /* Changed to blue-500 */
  font-size: 18px;
  border-radius: 8px;
  background-color: #f8fafc; /* Slightly lighter than blue-50 */
}

.newsletter-about, .newsletter-events, .newsletter-reminders {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #bfdbfe; /* Added border with blue-200 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.newsletter-about h2, .newsletter-events h2, .newsletter-reminders h2 {
  font-size: 24px;
  color: #1e3a8a; /* Changed to blue-900 */
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 2px solid #60a5fa; /* Added border with blue-400 */
}

.event-detail {
  border-bottom: 2px solid #bfdbfe; /* Changed to blue-200 */
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.event-detail:last-child {
  border-bottom: none;
}

.event-detail h3 {
  color: #1e40af; /* Changed to blue-800 */
  margin-bottom: 8px;
}

.event-detail p {
  color: #1e3a8a; /* Changed to blue-900 */
}

.event-detail .announcement-link {
  color: #3b82f6; /* Changed to blue-500 */
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.event-detail .announcement-link:hover {
  color: #2563eb; /* Changed to blue-600 */
}

.newsletter-reminders p {
  background-color: #3b82f6; /* Changed to blue-500 */
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #2563eb; /* Added border with blue-600 */
}

@media (max-width: 768px) {
  .newsletter-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    background-color: #1e3a8a; /* Changed to blue-900 */
    border-radius: 0px 0px 35px 35px;
    overflow-x: auto;
    white-space: nowrap;
  }

  .newsletter-container {
    margin-bottom: 60px;
  }

  .newsletter-content {
    flex-direction: column;
  }

  .newsletter-right {
    flex: none;
    margin: 20px;
  }
}

@media (max-width: 480px) {
  .newsletter-container {
    margin: 40px 0 60px 0;
  }

  .newsletter-content {
    flex-direction: column;
  }

  .newsletter-right {
    flex: none;
    margin: 20px;
  }

  .newsletter-header h1 {
    font-size: 24px;
  }
}