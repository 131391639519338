.classroom-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 20px 70px 90px;
}

.classroom-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 70px;
}

.day-card {
    background: #fff;
    padding: 0;  /* Remove padding here */
    border-radius: 8px;
    overflow: hidden;
    width: 30%; /* Adjusted to fit three in a row */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    margin: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.day-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0,0,0,0.3);
}

.day-card-header {
    padding: 10px;
    color: white;
    text-align: center;
    border-radius: 8px 8px 0 0;
}

.sunday { background-color: red; }
.monday { background-color: yellow; color: black; }
.tuesday { background-color: pink; }
.wednesday { background-color: green; }
.thursday { background-color: orange; }
.friday { background-color: blue; }
.saturday { background-color: purple; }

.classroom-card {
    padding: 10px;
    overflow: hidden;
    width: 95%;
    margin: 10px 0;
}

.classroom-info h3 {
    margin-top: 0;
    color: #005082;
    font-size: 16px;
}

.available {
    color: #b6b6b6;
    font-size: 12px;
    font-style: italic;
}

.classroom-day {
    margin-bottom: 15px;
}

.classroom-events-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.classroom-event {
    padding: 5px;
    background-color: #005082;
    color: white;
    margin: 5px 0;
    border-radius: 5px;
}

.classroom-view-tabs {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #3292e0;
    padding: 15px 20px;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .classroom-view-tabs button {
    padding: 10px;
    margin: 0 5px;
    border: none;
    border-radius: 5px;
    background-color: white !important;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    font-size: 16px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .classroom-view-tabs button:hover {
    background-color: #e9ecef;
    transform: translateY(-2px);
  }
  
  .classroom-view-tabs button.active {
    background-color: #FF6B6B !important; 
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Lift active tab */
  }
  

@media (max-width: 1200px) {
    .day-card {
        width: 45%; /* Adjust to fit two in a row */
    }
}

@media (max-width: 768px) {
    .classroom-page-container {
        margin: 0;
        margin-top: 60px;
    }
    .classroom-view-tabs {
        display: flex;
        justify-content: center;
        width: 91%;
        background-color: #3292e0;
        border-radius: 0px 0px 35px 35px;
        padding: 20px;
        overflow-x: auto;
        white-space: nowrap;
      }

    .day-card,
    .classroom-card {
        width: 90%;
    }
}
