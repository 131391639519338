.calendar-layout {
  display: flex;
  flex-wrap: wrap;
}

.calendar-container {
  padding: 1rem;
  flex: 2.4;
  transition: transform 0.3s ease;
}

.calendar-container:hover {
  transform: translateY(-5px);
}

.calendar-border {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.225);
}

/* Custom background color for class events */
.class-event {
  background-color: #158913bc !important;
  color: white; /* Adjust text color if needed */
  border: none; /* Remove border for a clean look */
}

.form-row {
  margin-bottom: 16px; /* Adjust the spacing as needed */
}

.schedule-event {
  background-color: #b99853da !important;
  color: white; /* Adjust text color if needed */
  border: none; /* Remove border for a clean look */
}
.task-list-container-side {
  padding: 1rem;
  flex: 0.9;
  overflow-y: auto;
}

.task-list-side {
  padding: 3px;
}

.task-item-side {
  display: flex;
  background-color: #fff;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.task-item-side.task-item {
  border-left: 5px solid #be6464;
  border-radius: 12px; 
}

.task-item-side.class-item {
  border-left: 5px solid #36A2EB; 
  border-radius: 12px;
}

.task-item-side:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.task-item-side .color-tab {
  width: 5px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.task-item-side .task-content {
  flex: 1;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-item-side .task-info {
  flex: 1;
}

.task-item-side .task-info h3 {
  margin: 0;
  font-size: 1.2em;
  color: #172a60;
}

.task-item-side .task-info p {
  margin: 0.2em 0;
  font-size: 1em;
  color: #333;
}

.task-item-side .task-info .task-time {
  color: #6c757d;
}

.task-badge {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-details {
  margin: 0.5rem 0;
}

.task-detail {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.task-detail .icon {
  margin-right: 0.5rem;
  color: #5a6065;
}

.task-detail .task-time {
  color: #5a6065;
}

.video-call-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 15px;
  border-radius: 8px;
  background-color: #0095da;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor to pointer */
}

.video-call-button:hover {
  background-color: #0056b3;
}

/* Pagination styles */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.page-button {
  padding: 5px 10px;
  margin: 0 2px;
  border: none;
  background-color: #e7e7e7;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.875rem;
}

.page-button:hover {
  background-color: #0095da;
}

.page-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.page-button.active {
  background-color: #0056b3;
  color: #fff;
}

.fc .fc-toolbar {
  margin-bottom: 1rem;
}

.fc .fc-toolbar-title {
  font-size: 1.3em;
  margin: 0px;
}

/* Calendar adjustments */
@media (max-width: 768px) {
  .calendar-container {
    padding: 0.5rem;
    width: 100%;
    margin-top: 20px;
  }

  .calendar-layout {
    flex-direction: column;
  }

  .task-list-container-side {
    flex: 1;
  }
  
}

/* Add border between days in the weekly view */
.fc-timegrid-col {
  border-right: 1px solid #ccc; /* Light grey color for the border */
}

.fc-timegrid-col.fc-day-sun {
  border-right: none; /* Remove the border on the last day column */
}

.fc-timegrid-axis {
  border-right: 1px solid #ccc; /* Light grey color for the time axis border */
}

/* Adjust the height of the time slots to make the borders more visible */
.fc-timegrid-slot {
  height: 1em; /* Adjust as needed */
}

.fc-dayGridMonth-view .fc-event {
  border: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 10px;
  color: #fff;
}

.fc-dayGridMonth-view .fc-event.class-event {
  background-color: #055083; /* Blue color for classes */
  color: #ffffff; /* White text color */
}

.fc-dayGridMonth-view .fc-event.task-event {
  background-color: #be6464; 
  color: #ffffff; /* White text color */
}

.fc-timeGridWeek-view .fc-event,
.fc-timeGridDay-view .fc-event {
  border: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 5px; 
  margin: 2px;
  border-radius: 4px;
}

.fc-timeGridWeek-view .fc-event.class-event,
.fc-timeGridDay-view .fc-event.class-event {
  background-color: #055083; /* Blue color for classes */
  color: #ffffff; /* White text color */
}

.fc-timeGridWeek-view .fc-event.task-event,
.fc-timeGridDay-view .fc-event.task-event {
  background-color: #be6464; 
  color: #ffffff; /* White text color */
}

.color-tab {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 50%;
}

/* Add more styles for additional teachers as needed */

.event-content {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9em;
  color: #ffffff; /* White text for better contrast on dark background */
  padding: 1px;
}

.event-title {
  font-size: 1em;
  color: #ffffff; /* White text color */
}

.event-description {
  font-size: 0.9em;
  color: #b3e5fc; /* Light blue text color for description */
}

.event-video-link a {
  color: #81d4fa; /* Light blue text color for video link */
  text-decoration: underline;
}


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 12px; /* More pronounced rounded corners */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  z-index: 1000;
  max-width: 400px; /* Adjust the maximum width as needed */
  width: 90%; /* Responsive width */
  max-height: 80vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}


.popup input[type="text"],
.popup textarea,
.popup select {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 8px; /* More rounded corners */
  font-size: 16px;
  background-color: #ffffff; /* Ensures input background is white */
}

.popup label {
  font-weight: bold;
  margin-top: 10px;
  display: block;
  color: #333;
}

.popup button {
  padding:5px 5px;
  margin-top: 20px;
  border: none;
  border-radius: 8px; /* More rounded corners */
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup button:hover {
  background-color: #0056b3;
}

.popup button:first-child {
  margin-right: 10px;
}

.popup .popup-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.popup .save-button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  border-radius: 8px;
  background-color: #0095da;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup .save-button:hover {
  background-color: #0095da;
}

.popup .delete-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #ff0000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup .delete-button:hover {
  background-color: #cc0000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.close-button:focus {
  outline: none;
}

.selected-date-time {
  background-color: #235d65;
  border: 1px solid #a0a0a0;
  color: #000000;
  padding: 8px; /* Added padding for better visibility */
  border-radius: 8px; /* Rounded corners */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.confirm-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.confirm-box p {
  margin-bottom: 20px;
}

.confirm-box button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 8px;
  background-color: #0095da;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-box button:hover {
  background-color: #0095da;
}

.confirm-box button:first-child {
  margin-right: 10px;
}

.confirm-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  max-width: 300px;
  width: 80%;
  text-align: center;
}

.confirm-box p {
  margin-bottom: 20px;
}

.confirm-box button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 8px;
  background-color: #0095da;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-box button:hover {
  background-color: #0095da;
}

.confirm-box button:first-child {
  margin-right: 10px;
}

.see-full-calendar-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.see-full-calendar-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.see-full-calendar-button:hover {
  background-color: #0056b3;
}

.task-list-container {
  width: 100%;
  overflow-x: auto;
}

.task-list-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.task-list-table th {
  background-color: #055083;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.task-list-table td {
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
}

.task-list-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.task-list-table tr:hover {
  background-color: #e9f5e9;
}

.task-list-table td a {
  color: #0095da;
  text-decoration: none;
}

.task-list-table td a:hover {
  text-decoration: underline;
}

.bold-column {
  font-weight: bold;
}

.upcoming,
.on-schedule,
.today {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: none;
  border-radius: 50px;
  white-space: nowrap;
}

.upcoming {
  background-color: #f0ad4e; /* Orange background for upcoming tasks */
  color: #000; /* Black text for contrast */
}

.on-schedule {
  background-color: #337ab7; /* Blue background for on schedule tasks */
  color: #fff; /* White text for contrast */
}

.today {
  background-color: #d9534f; /* Red background for today tasks */
  color: #fff; /* White text for contrast */
}
.task-list-cards {
  display: none;
}


.header-calendar {
  background: #777;
  text-align: center;
  height: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  margin-bottom: 20px; /* Space below the header */
  color: #fff; /* White text */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
}

.header-calendar h1 {
  font-size: 36px; /* Large font size */
  font-weight: bold;
  margin: 0; /* Remove default margin */
  text-transform: uppercase; /* Uppercase text */
  letter-spacing: 2px; /* Spacing between letters */
}


/* Responsive styles */
@media (max-width: 768px) {
  .task-list-table {
    display: none;
  }

  .task-list-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 60px;
  }
  .task-item-side {
    display: none;
  }

  .task-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  .task-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .task-card-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .task-card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

