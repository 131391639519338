.inventory-form {
    display: flex;
    flex-direction: column;
}

.inventory-form label {
    font-weight: bold;
    margin-bottom: 5px;
}

.inventory-form input,
.inventory-form textarea,
.inventory-form select {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #fff;
    transition: border-color 0.3s;
}

.inventory-form input:focus,
.inventory-form textarea:focus,
.inventory-form select:focus {
    border-color: #007BFF;
    outline: none;
}

.inventory-form button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    width: 200px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.inventory-form button:hover {
    background-color: #0056b3;
}

.pagination-container {
    display: flex;
    justify-content: left;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .page-button {
    padding: 5px 10px;
    margin: 0 2px;
    border: none;
    background-color: #e7e7e7;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.875rem;
  }
  
  .page-button:hover:not(:disabled) {
    background-color: #d7d7d7;
  }
  
  .page-button.disabled,
  .page-button:disabled {
    background-color: #f7f7f7;
    color: #000;
    cursor: not-allowed;
  }
  
  .page-button.active {
    background-color: #0095da;
    color: white;
  }

  .adjust-button {
    padding: 5px !important;
    font-size: 12px !important;
    background-color: #0095da !important;
  }