.reports-container {
  padding: 60px 20px 80px 90px;
  color: #333; /* Darker text for contrast */
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.report-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns layout */
  gap: 20px;
}

.report-box {
  background-color: rgba(255, 255, 255, 0.9); /* 90% opacity white */
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.report-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.performance-table,
.department-table {
  width: 100%;
  border-collapse: collapse;
}

.performance-table th,
.performance-table td,
.department-table th,
.department-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.performance-table th,
.department-table th {
  background-color: #e9ecef; /* Light grey background for headers */
}

.performance-table tbody tr:nth-child(odd),
.department-table tbody tr:nth-child(odd) {
  background-color: #f8f9fa; /* Slightly darker grey for odd rows */
}

.school-list {
  display: flex;
  flex-direction: column;
}

.school-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 5px;
}

.school-name {
  color: #f0ad4e; /* Orange */
}

.student-count {
  color: #337ab7; /* Teal blue */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .reports-container {
    padding: 40px 10px;
  }

  .report-grid {
    grid-template-columns: 1fr; /* Single column layout on smaller screens */
  }

  .report-box {
    padding: 15px;
  }

  .performance-table th,
  .performance-table td,
  .department-table th,
  .department-table td {
    padding: 8px;
    font-size: 0.9rem; /* Slightly smaller text */
  }

  .school-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .school-name,
  .student-count {
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .reports-container {
    padding: 20px 5px;
  }

  .report-box {
    padding: 10px;
  }

  .performance-table th,
  .performance-table td,
  .department-table th,
  .department-table td {
    font-size: 0.8rem; /* Smaller text for mobile */
  }
}
