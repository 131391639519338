/* General styling for the authentication container */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../public/tutorbg.jpg');
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
}

.auth-container > * {
  position: relative;
  z-index: 2;
}

/* Styling for the form container */
.form-container2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1e3a8a; /* Changed to blue-900 */
  color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 400px;
  margin: 20px;
  animation: fadeIn 0.5s ease-in-out;
}

.form-container2 h2 {
  color: #fff;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
}

.form-container2 span {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Grid layout for form fields */
.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group.full-width {
  grid-column: span 2;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #bfdbfe !important; /* Changed to blue-200 */
}

.form-group input {
  display: inline-block;
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #1e3a8a; /* Changed to blue-900 */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Styling for the terms container */
.terms-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #bfdbfe; /* Changed to blue-200 */
  margin-bottom: 10px;
}

.terms-checkbox {
  margin-right: 10px;
}

/* Styling for the button container */
.button-container {
  text-align: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #60a5fa; /* Changed to blue-400 */
  color: #1e3a8a; /* Changed to blue-900 for contrast */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #93c5fd; /* Changed to blue-300 */
  transform: translateY(-3px);
}

button:active {
  background-color: #3b82f6; /* Changed to blue-500 */
  transform: translateY(0);
}

/* Error message styling */
.error-message {
  color: #f87171;
  margin-top: 5px;
  font-size: 14px;
}

/* Reset password link styling */
.reset-container {
  margin-top: 20px;
}

.reset-text {
  color: #bfdbfe; /* Changed to blue-200 */
  cursor: pointer;
  font-size: 12px;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  outline: none;
}

.reset-text:hover, .reset-text:focus {
  color: #dbeafe; /* Changed to blue-100 */
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  border-radius: 5px;
  transform: scale(1.05);
}

.reset-text:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) inset;
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1);
}

/* Sign-in link styling */
.signin {
  font-size: 14px;
  text-decoration: underline;
  color: #bfdbfe; /* Changed to blue-200 */
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
  text-align: center;
}

.signin:hover {
  text-decoration: none;
  color: #dbeafe; /* Changed to blue-100 */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-container {
    padding: 1rem;
    width: 80%;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  h2 {
    font-size: 1.25rem;
  }

  .form-group label,
  .form-group input,
  .button-container button {
    margin-top: 0.5rem;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .form-container {
    width: 95%;
    padding: 1rem;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  h2 {
    font-size: 1.1rem;
  }
}