.performance-content {
  margin: 80px 20px 80px 90px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.cards-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #d9d9d9 #f5f5f5; /* Firefox */
  padding-bottom: 12px; /* Space for scrollbar */
  margin-bottom: 16px; /* Adjust spacing below the container */
}


.cards-container .ant-row {
  flex-wrap: nowrap !important;
}


.cards-container::-webkit-scrollbar {
  height: 6px;
}

.cards-container::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 3px;
}

.cards-container::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 3px;
  border: none;
}


/* Optional: Hide scrollbar but keep functionality */
@media (max-width: 768px) {

  .performance-content {
    padding: 16px;
    margin: 60px 0 80px 0;
  }
  .cards-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .cards-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
}

@media (max-width: 480px) {
  .performance-content {
    padding: 12px;
    margin: 60px 0 80px 0;
  }
}