.table-container {
  margin-left: 80px;
  margin-right: 20px;
  overflow-x: auto; /* Allows table to scroll horizontally on small screens */
  font-family: 'Roboto', sans-serif;
}

table {
width: 100%;
border-collapse: collapse;
background-color: #ffffff;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
overflow: hidden;
  overflow-x: scroll;
}

th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;

}

th {
font-weight: bold;
font-size: 14px;
text-transform: uppercase;

}
thead th {
padding: 10px;
text-align: left;

}

td {
border-bottom: 1px solid #dddddd;
font-size: 14px;
}

tr:hover {
background-color: #f5f5f5;
}

tbody tr:nth-child(odd) {
background-color: #f9f9f9;

}

tbody tr:nth-child(even) {
background-color: #e9e9e9;

}

tbody td {
padding: 3px;
vertical-align: middle; /* Center content vertically */
}

tr:hover {
  background-color: #f5f5f5;
}
/* Existing styles */

/* Add styles for pagination controls */
.pagination-container {
  display: flex;
  justify-content: left;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.page-button {
  padding: 5px 10px;
  margin: 0 2px;
  border: none;
  background-color: #e7e7e7;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.875rem;
}

.page-button:hover:not(:disabled) {
  background-color: #d7d7d7;
}

.page-button.disabled,
.page-button:disabled {
  background-color: #f7f7f7;
  color: #000;
  cursor: not-allowed;
}

.page-button.active {
  background-color: #0095da;
  color: white;
}

/* Ensure the table container does not have excessive width due to buttons */
.table-and-pagination-container {
  width: calc(100% - 160px); /* Adjust based on your actual sidebar width */
  margin-left: auto; /* Center the table when there is no sidebar */
}

/* General styles for the search container */
.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  width: 98%;
}

/* Styles for each input field */
.search-container input[type="text"] {
  padding: 10px 15px;
  width: 30%;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}


/* Hover and focus effects to enhance user interaction */
.search-container input[type="text"]:hover,
.search-container input[type="text"]:focus {
  border-color: #88aaff;
  outline: none; /* Removes the default focus outline */
  box-shadow: 0 0 8px rgba(0, 120, 250, 0.5); /* Soft blue glow */
}

.student-header {
width: 96.5%;
  display: flex;
  background: #fff;
  padding: 20px;
  border: 2px solid #005082;
  border-radius: 15px;
  color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}


.student-header-info h2{
margin: 10px;
  font-size: 30px;
  color: #333;
  margin-bottom: 20px;
}

.icon {
margin-right: 15px; /* Adjust the spacing between the icon and the text */
}

.paid-status {
  color: green;
}

.unpaid-status {
  color: red;
}

/* Adjust this as necessary for your application's layout */
@media (max-width: 480px) {
  .table-and-pagination-container {
      width: 100%;
      margin-left: 0;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .table-container {
      margin: 20px 10px; /* Reduce side margins on smaller screens */
      margin-top: 60px; /* Slightly reduce the top margin */
  }

  th, td {
      padding: 8px; /* Slightly reduce padding in table cells for smaller screens */
  }
}

@media (max-width: 480px) {
  /* Full-width table on very small screens */
  .table-container {
      margin: 10px 0; /* Minimal margin on very small devices */
      margin-top: 80px; /* Reduce top margin further */
  }

  /* Reduce font size in table headers and cells for better fit */
  th, td {
      font-size: 14px;
  }

  /* Make the floating action button smaller and reposition */
  .floating-button {
      width: 40px;
      height: 40px;
      right: 10px;
      bottom: 10px;
  }
}

/* Panel overlay for detailed view */
.panel-overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 99;
background: rgba(255, 255, 255, 0.9);
display: -moz-flex;
display: -ms-flex;
display: -o-flex;
display: flex;
justify-content: center;
-ms-align-items: center;
align-items: center;
opacity: 0;
animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.right-panel-popup {
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 90%;
max-width: 300px;
max-height: 90%;
overflow-y: auto;
background-color: #fff;
border-radius: 15px;
box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
padding: 20px;
z-index: 1001;
display: flex;
flex-direction: column;
align-items: center;
animation: fadeInFromCenter 0.3s ease-in-out; /* Apply the animation */
}
.student-details {
width: 100%;
text-align: center;
}

.student-details h2 {
margin: 20px 0 10px;
font-size: 24px;
color: #333;
}

.student-details p {
margin: 5px 0;
font-size: 16px;
color: #555;
}

.student-details .section-title {
margin-top: 20px;
margin-bottom: 10px;
font-size: 18px;
font-weight: bold;
color: #333;
}

.student-details .contact-info {
display: flex;
justify-content: center;
gap: 15px;
margin: 10px 0 20px;
color: #4A4AAB;
}

.student-details .contact-info div {
display: flex;
align-items: center;
gap: 5px;
}

.student-details .contact-info div svg {
font-size: 20px;
}
.student-image-container {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 15px;
}

.right-panel {
  padding: 20px;
}

/* Ensure modal and its content are scrollable if needed */
.modal-content,
.right-panel {
  overflow-y: auto;
  max-height: 80vh;
}


/* Dashboard */
.student-dashboard-container {
display: flex !important;
flex-direction: column !important;
align-items: center !important;
}
.student-dashboard-container-admin {
display: flex !important;
flex-direction: column !important;
align-items: center !important;
margin: 80px 20px 60px 80px;
}


.student-dashboard-content {
display: flex;
justify-content: space-between;
width: 100%;
margin-bottom: 20px;
}
.detail-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.detail-label {
  font-weight: 600;
  margin-right: 8px;  /* Adds space between label and content */
  min-width: 85px;    /* Ensures consistent width for labels */
  flex-shrink: 0;     /* Prevents label from shrinking */
}

.detail-content {
  flex: 1;
}

/* Rest of your existing styles */
.student-card-container {
  display: grid;
  gap: 16px;
}

.enrollment-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.enrollment-details {
  flex: 1;
}

.view-details-link {
  display: block;
  text-align: right;
  margin-top: 16px;
  padding-top: 12px;
  border-top: 1px solid #f0f0f0;
  color: #1890ff;
  transition: color 0.3s ease;
}

.view-details-link:hover {
  color: #40a9ff;
}

.student-id-card {
  background-color: #055083; 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  padding: 40px 20px;
  text-align: center;
  position: relative;
  width: 290px; 
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.student-id-card img {
  border-radius: 8px;
  width: 150px;
  height: 180px;
  object-fit: cover;
  margin-bottom: 15px;
  margin-top: 15px;
}

.student-id-card .student-logo {
  position: absolute;
  top: -30px; /* Adjust the position as needed */
  right: 40px; /* Adjust the position as needed */
  width: 120px; 
  height: 120px; 
  object-fit: contain;
  border-radius: 0 !important;
  padding: 5px !important;
  transform: rotate(20deg); /* Rotate the logo 45 degrees */
}

.student-id-card h4 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
  color: white;
}

.student-id-card .student-name {
  font-size: 22px;
  font-weight: bold;
  margin: 10px 0;
}

.student-id-card .student-year {
  font-size: 16px;
  color: #FFCC00; /* Gold color for the year */
  margin: 5px 0;
}

.student-id-card .student-qr {
  margin-top: 20px;
  padding: 7px;
  background-color: #fff;
  border-radius: 8px;
}

 /*All Enrollment Class */
 .student-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

.student-card {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 280px;
  margin: 15px;
  transition: box-shadow 0.3s ease;
}

.student-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.student-card-header {
  background-color: #2A4860; /* Navy blue */
  padding: 10px;
  border-radius: 15px 15px 0 0;
  text-align: center;
}

.student-class-code-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.student-card-body {
  padding: 15px;
  text-align: center;
}

.student-card-subjects {
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.student-class-code-button {
  background: #FF6B6B !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  padding: 8px 15px !important;
  cursor: pointer !important;
  transition: background 0.3s ease !important;
}

.student-card-schedule button {
  background: #be6464 !important;
  color: white !important;
  border: none !important;
  border-radius: 20px !important;
  padding: 8px 15px !important;
  cursor: pointer !important;
  transition: background 0.3s ease !important;
}

.student-card-schedule button:hover {
  background: #FF8E53 !important;
}

.student-card-hours {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}

@media (max-width: 768px) {
  .student-card-container {
    justify-content: center;
  }

  .student-card {
    width: 100%;
    max-width: 350px;
  }
}


.classes-calendar-container {
display: flex;
justify-content: space-between;
margin-bottom: 20px;
gap: 20px;
}
.student-profile,
.student-attendance,
.today-classes {
background-color: #fff;
padding: 15px;
border-radius: 15px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
width: 30%;
}

.test-score-form {
display: flex;
flex-wrap: wrap;
gap: 10px; /* Adjust the gap between elements */
padding: 20px;
}

.test-score-form input {
padding: 5px;
border: 1px solid #ccc;
border-radius: 5px;
font-size: 11px;
transition: border-color 0.3s;
flex: 1 1 calc(33.33% - 20px); /* Adjust width and gap */
}

.test-score-form button {
background-color: #0095da; /* Active blue button */
color: #ffffff;
padding: 5px 10px;
border: none;
border-radius: 5px;
font-size: 11px; /* Smaller font size for the button */
cursor: pointer;
transition: background-color 0.3s, transform 0.3s;
align-self: center;
margin-top: 5px; /* Add space between inputs and button */
width: 50%; /* Make button span the entire row */
}

.test-score-form button:hover {
background-color: #0095da; /* Darker blue */
transform: translateY(-2px);
}

.test-score-form button:active {
background-color: #005181; /* Even darker blue */
transform: translateY(0);
}

.test-score-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr)); /* Adjust card width */
  gap: 15px;
  padding: 10px;
  border-bottom: solid #333 1px;
}


/* Header row - Type and Name */
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.test-type {
  font-size: 1rem;
  color: #005181;
  font-weight: bold;
}

.test-name {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
}

/* Body content */
.test-card-body {
  margin-top: 8px;
}

.score-row {
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  font-size: 0.85rem;
}

/* Note row */
.test-note {
  margin-top: 10px;
  color: #777;
  font-size: 0.85rem;
}


/* Student Info Container */
.today-classes {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Student Info List - Cards */
.student-info-list {
  max-width: 100%;
  width: 100%; 
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.student-dashboard-layout {
  min-height: 100vh;
  margin-top: 70px;
    margin-left: 70px;
}


@media (min-width: 768px) {
  .student-dashboard-content {
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 480px) {
  .student-dashboard-content {
    margin-top: 10px;
    margin-left: 10px;
  }
}
/* Individual Student Info Card */
.student-info-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  word-wrap: break-word; 
  overflow-wrap: break-word;
}

.info-content p {
  margin: 0;
  font-size: 0.8rem;
  color: #333;
  word-wrap: break-word; /* Ensures long words break */
  overflow-wrap: break-word; /* Alternative for better browser support */
  white-space: normal; /* Ensures text wraps to the next line */
  max-width: 100%; /* Restrict the width */
}

.info-content span {
  font-weight: bold;
  color: #666;
}

/* Delete button inside the card */
.info-actions button.delete-score {
  background-color: transparent;
  border: none;
  color: #e74c3c;
  font-size: 1rem;
  cursor: pointer;
}

.info-actions button.delete-score:hover {
  color: #c0392b;
}

/* Add Student Info Form */
.student-info-form {
  margin-top: 20px;
}

.student-info-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  font-size: 1rem;
  resize: none;
}

.student-info-form button.add-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.student-info-form button.add-button:hover {
  background-color: #2980b9;
}

.delete-score {
background-color: #fff !important;
padding: 3px;
color: #e0e0e0 !important;
}

.delete-score:hover {
background-color: #be6464 !important;
padding: 3px;
color: #fff !important;
}

.student-info-form {
display: flex;
flex-direction: column;
gap: 10px;
margin-top: 20px;
}

.student-info-form textarea {
padding: 10px;
border: 1px solid #ccc;
border-radius: 5px;
font-size: 14px;
transition: border-color 0.3s;
}

.student-info-form button {
background-color: #0095da;
color: #ffffff;
padding: 10px;
border: none;
border-radius: 5px;
cursor: pointer;
align-self: center;
}

.student-info-form button:hover {
background-color: #0095da;
}
.student-profile img {
display: block;
margin: 0 auto 20px;
}

.student-profile ul {
list-style: none;
padding: 0;
}

.student-profile li {
background-color: #0095da;
color: white;
padding: 5px;
border-radius: 3px;
margin-bottom: 5px;
}
.student-image2 {
width: 150px; /* This sets a fixed width */
height: auto; /* This maintains aspect ratio */
border-radius: 10%; /* This creates a circle shape */
margin-bottom: 10px;
display: inline-block; /* Changed from block to inline-block */
}


.classes-list {
display: flex;
flex-direction: column;
gap: 10px;
}

.class-item {
display: flex;
flex-direction: column;
padding: 10px;
border: 1px solid #e0e0e0;
border-radius: 5px;
background-color: #f9f9f9;
}

.class-item .class-time {
font-weight: bold;
color: #333;
}

.class-item .class-title {
margin: 5px 0;
font-size: 1rem;
color: #0095da;
}

.class-item .class-link a {
text-decoration: none;
color: #0095da;
}

.class-item .class-status {
font-size: 0.9rem;
}

.class-item .class-status.due-soon {
color: red;
}

.class-item .class-status.upcoming {
color: orange;
}

.status-absent {
  color: red;
  font-weight: bold;
}

.status-late {
  color: orange;
  font-weight: bold;
}


.all-classes {
width: 98%;
background-color: #fff;
padding: 15px;
border-radius: 15px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
margin-top: 20px;
margin-bottom: 20px;
}

.all-classes h2 {
margin-bottom: 10px;
font-size: 1.5rem;
color: #333;
}

.all-classes table {
width: 100%;
border-collapse: collapse;
margin-top: 10px;
}

.all-classes th, .all-classes td {
border: 1px solid #ddd;
padding: 10px;
text-align: left;
}

.all-classes th {
background-color: #005082;
color: white;
font-weight: bold;
}

.all-classes tr:nth-child(even) {
background-color: #f9f9f9;
}

.all-classes tr:hover {
background-color: #f1f1f1;
}

.all-classes button {
background-color: #0095da;
color: white;
border: none;
padding: 5px 10px;
border-radius: 3px;
cursor: pointer;
}

.all-classes button:hover {
background-color: #0095da;
}

.additional-info {
display: flex;
justify-content: space-between;
width: 100%;
padding: 20px;
margin-bottom: 20px;
}

.attendance-logs,
.billing-history {
width: 98%;
background-color: #fff;
padding: 15px;
border-radius: 15px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.attendance-logs h2, .billing-history h2 {
margin-bottom: 10px;
font-size: 1.5rem;
color: #333;
}

.attendance-logs table, .billing-history table {
width: 100%;
border-collapse: collapse;
margin-top: 10px;
}

.attendance-logs th, .attendance-logs td,
.billing-history th, .billing-history td {
border: 1px solid #ddd;
padding: 10px;
text-align: left;
}

.attendance-logs th, .billing-history th {
background-color: #005082;
color: white;
font-weight: bold;
}

.attendance-logs tr:nth-child(even), .billing-history tr:nth-child(even) {
background-color: #f9f9f9;
}

.attendance-logs tr:hover, .billing-history tr:hover {
background-color: #f1f1f1;
}

.attendance-logs button, .billing-history button {
background-color: #0095da;
color: white;
border: none;
padding: 5px 10px;
border-radius: 3px;
cursor: pointer;
}

.attendance-logs button:hover, .billing-history button:hover {
background-color: #0095da;
}
.student-list-box-container {
display: flex;
flex-direction: column;
background-color: #fff;
padding: 15px;
border-radius: 15px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.student-list-box {
width: 97%;
margin-bottom: 20px;
margin-top: 20px;
padding: 20px;
background-color: #fff;
border-radius: 15px;
}

.classes-list {
display: flex;
flex-direction: column;
gap: 20px;
}

.class-card-date {
font-size: 1.2em;
font-weight: bold;
margin-bottom: 10px;
}

.class-card-details {
display: flex;
flex-wrap: wrap;
gap: 10px;
}

.class-card-item {
flex: 1 1 calc(50% - 10px); /* Adjust width and gap for items */
font-size: 1em;
color: #555;
}

.class-card {
display: none;
}

.pagination {
display: flex;
justify-content: center;
margin-top: 20px;
}

.pagination button {
background-color: #0095da;
color: white;
border: none;
padding: 5px 10px;
border-radius: 3px;
cursor: pointer;
margin: 0 5px;
}

.pagination button:hover {
background-color: #0095da;
}

.pagination button:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.student-schedule {
width: 100%;
margin-top: 20px;
}

.student-schedule .fc {
background-color: #fff;
padding: 20px;
border-radius: 5px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.table th, .table td {
padding: 10px;
text-align: left;
border-bottom: 1px solid #ddd;
}
.add-button {
background-color: #0095da;
}

.billing-history-cards {
display: flex;
flex-wrap: wrap;
gap: 20px;
}

.billing-card {
background: #8ec572a0;
border-radius: 8px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
padding: 20px;
width: calc(100%); /* Adjust this for the desired number of columns */
box-sizing: border-box;
}

.billing-card-content {
display: flex;
flex-direction: column;
gap: 10px;
}

.billing-card-header {
font-size: 1.2em;
font-weight: bold;
}

.billing-card-hours {
font-size: 1em;
color: #555;
}

@media (max-width: 480px) {
.billing-card {
  width: 100%; /* Single column on very small screens */
}

/* Full-width modal on very small screens */
.right-panel-popup,
.modal-content {
  width: 95%;
  max-width: none;
}
}

@media (max-width: 1024px) {
.student-dashboard-content {
  flex-direction: column;
  align-items: center;
}

.student-profile,
.student-attendance,
.today-classes {
  width: 95%;
  margin-bottom: 20px;
}
.student-list-box-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.all-classes {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.additional-info {
  flex-direction: column;
  align-items: center;
}

.attendance-logs,
.billing-history {
  width: 95%;
  margin-bottom: 20px;
}
}

@media (max-width: 768px) {

.student-header {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0px;
}
.student-dashboard-container {
  padding: 0 !important;
  flex-direction: column !important;
  align-items: center!important ;
  margin: 20px!important;
}

.student-dashboard-container-admin {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin: 80px 20px;
}

.student-dashboard-content {
  flex-direction: column;
  align-items: center;
}
.classtable {
  display: none;
}
.class-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #333;
  width: 90%;
 }

.student-profile,
.student-attendance,
.today-classes,
.student-list-box {
  width: 90%;
  margin-bottom: 20px;
}

.student-list-box-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 93%;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.additional-info {
  flex-direction: column;
  align-items: center;
}

.attendance-logs,
.billing-history {
  width: 100%;
  margin-bottom: 20px;
}
}

@media (max-width: 480px) {
.student-dashboard-container {
  padding: 0 !important;
  flex-direction: column;
  align-items: center;
  margin: 20px!important;

}

.student-dashboard-container-admin {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin: 80px 20px;
}
.student-dashboard-content {
  flex-direction: column;
  align-items: center;
}
.student-header {
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin-bottom: 20px;
}
.classtable {
  display: none;
}

.student-list-box-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 93%;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.student-profile,
.student-attendance,
.today-classes {
  width: 95% !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px !important;

}
.class-card {
display: flex;
flex-direction: column;
padding: 10px;
margin-bottom: 10px;
border-bottom: 1px solid #333;
width: 90%;
}

.student-profile h2, .student-attendance h2, .today-classes h2 {
  font-size: 1.2rem;
}

.class-item .class-time, .class-item .class-title, .class-item .class-link a, .class-item .class-status {
  font-size: 0.9rem;
}

.additional-info {
  flex-direction: column;
  align-items: center;
}

.attendance-logs,
.billing-history {
  width: 93%;
  margin-bottom: 20px;
}

.attendance-logs h2, .billing-history h2 {
  font-size: 1.2rem;
}

.all-classes {
    padding: 10px !important;
    width: 95% !important;
}

.all-classes h2 {
  font-size: 1.2rem;
}

.all-classes th, .all-classes td {
  padding: 5px;
}
}
