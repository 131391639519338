/* LanguageSwitcher.css */
.language-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .language-dropdown {
    padding: 5px 5px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
    background-color: #fff;
    color: #0095da;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  
  .language-dropdown:hover {
    background-color: #fff;
    color: #0095da;
  }
  