.registration-container {
  margin: 80px 80px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.registration-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Ensure the form elements wrap on smaller screens */
}

.form-left,
.form-right {
  width: 48%;
}

.re-form-address p {
  display: block;
  margin: 10px 0;
}

.re-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.re-form-group label {
  flex: 1;
  font-weight: bold;
  margin-right: 8px;
}

.re-form-group input,
.re-form-group textarea {
  flex: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.re-form-group select {
  flex: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.schedule-pop-item {
  background-color: #ffffff;
  padding: 8px;
  margin: 4px;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.schedule-item {
  background-color: #ffffff3b;
  padding: 1rem;
  border-radius: 12px;
  flex: 1 1 200px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price-display {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  border-radius: 15px;
  border: 2px solid #333;
  padding: 5px;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.paid-button {
  background-color: #be6464;
  padding: 5px 10px;
  font-size: 12px;

}
.quotation-button {
  background-color: #136e3d !important;
  color: white;
  padding: 5px 5px;
  gap: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;

}
.submit-button {
  background-color: #0095da !important;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button svg {
  margin-right: 8px;
}

.change-class {
  padding: 3px 10px;
  background-color: #be6464;
  font-size: 14px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.modal-content div {
  margin-bottom: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.confirm-button, .cancel-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-button {
  background-color: #28a745;
  color: #fff;
}

.cancel-button {
  background-color: #dc3545;
  color: #fff;
}


.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.confirm-button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.singature-button {
  background-color: #772727a4 !important;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.custom-checkbox label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #0095da;
  border-radius: 4px;
  background: white;
  margin-right: 8px;
}

.custom-checkbox input[type="checkbox"]:checked + label::before {
  background: #0095da;
  border-color: #0095da;
}

.custom-checkbox input[type="checkbox"]:checked + label::after {
  content: '✓';
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  left: 4px;
  top: 2px;
  font-size: 12px;
}

.vat-amount {
  font-size: 1.1em;
  margin-left: auto;
  color: #216c24;
}


.registration-list {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.table-container {
  overflow-x: auto;
  width: 100%;
}

.student-classes-table {
  width: 100%;
  border-collapse: collapse;
}

.student-classes-table th,
.student-classes-table td {
  border: 1px solid #ddd;
  padding: 3px;
  font-size: 14px;
  text-align: left;
  word-wrap: break-word; 
  white-space: normal; 
  overflow-wrap: anywhere;
}

/* Signature pad */
.sigCanvas {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 80%;
  max-width: 600px; /* Set a maximum width */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center the element */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.test-score-container {
  padding: 20px;
}

.test-score-form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.test-score-form {
  max-width: 600px;
  margin: 20px auto;
}

.test-score-form .form-group {
  margin-bottom: 15px;
}

.test-score-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.test-score-form input,
.test-score-form select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.test-score-form input[type="date"],
.test-score-form input[type="text"],
.test-score-form select {
  height: 45px;
}

.test-score-form select {
  appearance: none;
  background: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23444' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 10px center;
  background-color: #fff;
  background-size: 10px;
}

.test-score-form .submit-button {
  width: 100%;
  padding: 10px;
  background-color: #055083;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.test-score-form .submit-button:hover {
  background-color: #003f6b;
}

.test-score-form .error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast {
  background-color: #fff;
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.date-picker-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.date-picker-container label {
  font-weight: bold;
}

.date-picker-container input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .registration-container {
    margin: 40px 10px;
    padding: 15px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .modal-content {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  

  .registration-form {
    flex-direction: column;
  }

  .form-left,
  .form-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .submit-button {
    width: 100%;
  }
  .sigCanvas {
    max-width: 80%;
  }
  .test-score-form {
    padding: 15px;
  }

  .test-score-form input,
  .test-score-form select {
    font-size: 14px;
    padding: 8px;
  }

  .test-score-form .submit-button {
    font-size: 16px;
  }
}



@media (max-width: 480px) {
  .registration-container {
    margin: 20px 5px;
    padding: 10px;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .submit-button {
    padding: 8px 15px;
  }

  .price-display {
    font-size: 1em;
    padding: 5px;
  }

  .sigCanvas {
    max-width: 70%;
}
}
