.left-panel {
    width: 70px;
    background-color: #eff6ff; /* Changed to blue-50 */
    color: #1e40af; /* Changed to blue-800 */
    border-right: 1px solid #bfdbfe; /* Changed to blue-200 */
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    transition: width 0.3s ease;
    z-index: 2;
    overflow: hidden;
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
  }
  
  .menu-list {
    list-style: none;
    padding: 0;
    margin-top: 4rem;
    overflow-y: auto;
    height: calc(100% - 6.5rem);
  }
  
  .menu-list::-webkit-scrollbar {
    width: 4px;
  }
  
  .menu-list::-webkit-scrollbar-track {
    background: #dbeafe; /* Changed to blue-100 */
  }
  
  .menu-list::-webkit-scrollbar-thumb {
    background: #60a5fa; /* Changed to blue-400 */
    border-radius: 4px;
  }
  
  .menu-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    color: inherit;
    width: 100%;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .menu-link:hover, .menu-link.active {
    background-color: #bfdbfe; /* Changed to blue-200 */
    color: #1e3a8a; /* Changed to blue-900 */
    border-radius: 50px 0 0 50px;
  }
  
  .menu-icon {
    font-size: 18px;
    width: 30px;
    text-align: center;
    margin-bottom: 5px;
    transition: color 0.3s ease;
  }
  
  .menu-text {
    font-size: 11px;
    text-align: center;
    white-space: nowrap;
    color: #1e3a8a; /* Changed to blue-900 */
  }
  
  @media (max-width: 768px) {
    .left-panel {
      width: 60px;
    }
  
    .menu-link:hover, .menu-link.active {
      background-color: #bfdbfe; /* Changed to blue-200 */
      color: #1e3a8a; /* Changed to blue-900 */
      border-radius: 50%;
    }
  }
  
  @media (max-width: 480px) {
    .left-panel {
      width: 100%;
      height: 70px;
      top: auto;
      bottom: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      border-top: 1px solid #3b82f6; /* Changed to blue-500 */
      background-color: #eff6ff; /* Changed to blue-50 */
    }
  
    .menu-list {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 16px;
      padding: 0;
      margin: 0;
      height: auto;
    }
  
    .menu-link {
      flex-direction: row;
      justify-content: center;
      width: auto;
      padding: 10px;
    }
  
    .menu-link:hover, .menu-link.active {
      background-color: #1e3a8a; /* Changed to blue-900 */
      color: #eff6ff; /* Changed to blue-50 */
      border-radius: 10px;
      padding: 10px;
    }
  
    .menu-icon {
      margin-bottom: 0;
      font-size: 22px;
      padding: 4px;
    }
  
    .menu-text {
      display: none;
    }
  }
  
  /* Animation for active state */
  .menu-link.active .menu-icon {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  /* Optional: Add hover effect for menu items */
  .menu-link:hover .menu-icon {
    transform: translateY(-2px);
    transition: transform 0.3s ease;
  }