/* PayStubModal.css */
.paystub-modal-overlay {
    position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  overflow-y: auto; /* Enable scrolling on the overlay */
}

.paystub-modal {
  background: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  width: 900px;
  max-width: 100%;
  max-height: 80vh; /* Limit the height of the modal */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  overflow-y: auto; /* Enable scrolling inside the modal */
  position: relative; /* For the close button positioning */
}
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    color: #000;
    cursor: pointer;
  }
  
  .modal-close-button:hover {
    color: #ff0000;
  }
  
  .paystub-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .paystub-header .company-info h1 {
    margin: 0;
  }
  
  .paystub-header .paystub-info {
    text-align: right;
  }
  
  .employee-info {
    margin-bottom: 20px;
  }
  
  .employee-info p {
    margin: 0 0 5px 0;
  }
  
  .paystub-table, .taxes-info {
    margin-bottom: 20px;
  }
  .downloadpdf {
    padding: 5px !important;
    background-color: #c3c3c39e !important;
  }
  
  
  .totals {
    text-align: right;
    margin-bottom: 20px;
  }
  
  .totals p {
    margin: 0;
  }
  
  .paystub-actions {
    text-align: center;
  }
  
  .paystub-actions button {
    padding: 10px 20px;
    background-color: #0095da;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .paystub-actions button:hover {
    background-color: #0095da;
  }
  @media print {
    .paystub-modal-overlay {
      display: block;
      position: static;
    }
  
    .paystub-modal {
      box-shadow: none;
      width: 100%;
      height: auto;
      background: none;
    }
  
    .modal-close-button {
      display: none; /* Hide close button during print */
    }
  }
  