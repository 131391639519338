@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  background-color: #f0f2f5; 
}


body, code, h1, h2, h3, h4, h5, h6, li, button, tr, td, span, input, select, a {
  font-family: 'Kanit', sans-serif;
}

p, textarea {
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
}

body.lang-en, body.lang-en code, body.lang-en h1, body.lang-en h2, body.lang-en h3, body.lang-en h4, body.lang-en h5, body.lang-en h6, body.lang-en p, body.lang-en li, body.lang-en span, body.lang-en button, body.lang-en tr, body.lang-en td, body.lang-en input, body.lang-en select, body.lang-en textarea {
  font-family: 'Poppins', sans-serif;
}
