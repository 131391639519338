.doodle-container {
    text-align: center;
    padding: 20px;
    margin: 60px 20px 80px 70px;
  }
  
  .doodle-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .doodle-tools {
    display: flex;
    flex-direction: column; /* Align items vertically */
    gap: 10px;
    margin-left: 20px; /* Adjust spacing between canvas and tools */
  }
  
  .doodle-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #005082 !important;
    color: #fff;
    transition: background-color 0.3s;
    width: 100%; /* Make buttons full width */
  }
  
  .doodle-btn:hover {
    background-color: #8dc572 !important;
  }
  
  .brush-options, .color-options {
    display: flex;
    flex-direction: column; /* Align label and input vertically */
    align-items: flex-start; /* Align contents to the left */
    margin: 10px 0;
  }
  
  .brush-options label, .color-options label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="range"] {
    cursor: pointer;
    width: 100%; /* Make slider full width */
  }
  
  input[type="color"] {
    cursor: pointer;
    border: none;
    width: 40px;
    height: 40px;
    padding: 0;
    margin-top: 5px;
  }
  /* For medium screens like tablets */
@media (max-width: 768px) {
    .doodle-content {
      flex-direction: column; /* Stack canvas and tools vertically */
      align-items: center;
      width: 100%;
    }
  
    .doodle-tools {
      margin-left: 0;
      margin-top: 20px; /* Add spacing above tools */
      width: 100%;
      max-width: 300px; /* Limit the width of the tools section */
    }
  
    .doodle-btn {
      font-size: 14px;
      padding: 8px 16px;
    }
  
    .brush-options, .color-options {
      width: 100%; 
    }
  }
  
  /* For small screens like mobile phones */
  @media (max-width: 480px) {
    .doodle-container {
      margin: 20px 10px; 
      padding: 10px;
      width: 450px;
    }
    .doodle-content {
        flex-direction: column; 
        align-items: center;
        width: 450px;
      }
  
    .doodle-tools {
      gap: 5px;
      max-width: 100%; /* Make tools take full width */
    }
  
    .doodle-btn {
      font-size: 12px;
      padding: 6px 12px;
    }
  
    .brush-options, .color-options {
      align-items: center;
      width: 100%; /* Ensure inputs stretch to the container width */
    }
  
    input[type="range"] {
      width: 100%; /* Ensure range slider fits smaller screens */
    }
  
    input[type="color"] {
      width: 30px;
      height: 30px;
    }
  }