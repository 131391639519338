.match-container {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Adjust gap between boxes */
    margin-top: 20px; /* Optional margin for spacing */
}

.match-box, .matched-box {
    width: 48%; /* Adjust width to fit two boxes in a row */
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
    background-color: #f9f9f9; /* Light background for clarity */
}

.match-box h2, .matched-box h3 {
    margin-bottom: 10px;
}

.match-box div, .matched-box div {
    margin-bottom: 15px;
}

.tab-container-language {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
}

.tab-container-language button {
    background-color: #f1f1f1;
    color: #333;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    transition: background-color 0.3s ease;
}

.tab-container-language button.active {
    background-color: #0095da; /* Change this to the desired active color */
    color: white;
}
.grid-container-match {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 25px;
    margin-top: 20px;
    padding: 20px;
}

.grid-item-match {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

.grid-item-match h4 {
    color: #005181;
    font-size: 1.1em;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 2px solid #e0e0e0;
}

.grid-item-match ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.grid-item-match ul li {
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 0.9em;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid-item-match ul li:last-child {
    border-bottom: none;
}

.grid-item-match:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .grid-container-match {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        padding: 15px;
    }

    .grid-item-match {
        padding: 15px;
    }

    .grid-item-match h4 {
        font-size: 1em;
    }

    .grid-item-match ul li {
        font-size: 0.85em;
    }
}

@media (max-width: 480px) {
    .grid-container-match {
        grid-template-columns: 1fr;
    }
}