.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #1e3a8a; /* Changed to blue-900 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.company-logo {
  height: 40px;
}

.user-info-and-signout {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-info {
  color: #dbeafe; /* Changed to blue-100 */
  font-size: 18px;
  margin-right: 20px;
}

.user-icon {
  margin-right: 5px;
  color: #bfdbfe; /* Changed to blue-200 */
}

.chat-btn {
  background: #3b82f6 !important; /* Changed to blue-500 */
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  position: relative;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

.chat-btn:hover {
  background: #2563eb !important; /* Changed to blue-600 */
}

.chat-icon {
  font-size: 18px;
  color: #fff;
}

.notification-badge {
  position: absolute;
  top: -7px;
  right: -7px;
  background-color: #ef4444;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  min-width: 18px;
  text-align: center;
}

.profile-popup {
  position: absolute;
  top: 60px;
  right: 20px;
  padding: 20px;
  background: #eff6ff; /* Changed to blue-50 */
  color: #1e3a8a; /* Changed to blue-900 */
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.profile-popup.show {
  opacity: 1;
  visibility: visible;
}

.profile-content {
  text-align: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 3px solid #60a5fa; /* Changed to blue-400 */
}

.doodle-header-btn {
  background: #2563eb !important; /* Changed to blue-600 */
  border: none;
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.doodle-icon {
  font-size: 20px;
  color: #fff;
}

.doodle-header-btn:hover {
  background: #1d4ed8 !important; /* Changed to blue-700 */
}

.change-password-btn {
  background-color: #60a5fa; /* Changed to blue-400 */
  color: #1e3a8a; /* Changed to blue-900 */
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.change-password-btn:hover {
  background-color: #3b82f6; /* Changed to blue-500 */
}

.cancel-btn {
  background-color: #f87171;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.cancel-btn:hover {
  background-color: #ef4444;
}

.change-password-form {
  margin-top: 20px;
  text-align: left;
}

.change-password-form label {
  display: block;
  margin-bottom: 10px;
  color: #1e3a8a; /* Changed to blue-900 */
}

.change-password-form input {
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #bfdbfe; /* Changed to blue-200 */
  border-radius: 4px;
  background-color: #fff;
  color: #1e3a8a; /* Changed to blue-900 */
}

.change-password-buttons {
  display: flex;
  justify-content: center;
}

.sign-out-btn {
  padding: 5px 8px;
  background-color: #3b82f6; /* Changed to blue-500 */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.sign-out-btn:hover {
  background-color: #2563eb; /* Changed to blue-600 */
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .header {
    padding: 0 15px;
  }

  .company-logo {
    height: 20px;
  }
}

@media (max-width: 768px) {
  .header {
    justify-content: space-between;
  }

  .user-info-and-signout {
    display: flex;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 0 10px;
  }

  .company-logo {
    height: 20px;
  }

  .user-info {
    display: none;
  }
}