.accounting-container {
  padding: 20px;
  margin-top: 60px;
  margin-left: 70px;
}

h1 {
  margin-bottom: 20px;
}
.invoice-logo {
  height: 120px;
  width: 120px;
  align-items: left; 
}

.invoice-header-logo {
  display: flex;
  justify-content: flex-end; 
  align-items: right; 
  margin-bottom: 20px;
}

.company-info {
  text-align: left; 
  margin-right: 20px; 
  align-items: right;
}

.invoice-header-right {
  width: 50%;
  text-align: right;
  font-size: 0.9rem;
}

.bill-to {
  margin-bottom: 20px;
}

.invoice-summary {
  margin-top: 20px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  padding: 3px 0;
  font-size: 1rem;
}

.summary-item span {
  flex: 1;
  text-align: right;
}

.grand-total {
  font-weight: bold;
  margin-top: 10px;
}

.grand-total span {
  text-align: right;
}

@media print {
  .invoice-logo {
    width: 100px;  /* Set the desired width for printing */
    height: auto;  /* Keep aspect ratio */
  }

  .invoice-modal {
    width: 100%;  /* Make sure the modal uses full width for print */
    max-width: 100%;  /* Override any max-width */
  }
}


.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filter-container label {
  margin-right: 10px;
}

.filter-container input,
.filter-container select {
  margin-left: 5px;
}

.filter-button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #337ab7;
  color: white;
  border: none;
  cursor: pointer;
}

.export-button {
  padding: 5px 10px;
  background-color: #28a745;
  border-radius: 5px;
  color: white;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.export-button svg {
  margin-right: 5px;
}

.accounting-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.accounting-table th,
.accounting-table td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: left;
  font-size: 13px;
}

.accounting-table th {
  color: white;
}

.generate-button {
  background-color: #0095da;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.generate-button svg {
  margin-right: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color: #d66969;
  cursor: pointer;
}

.pagination button.active {
  background-color: #0095da;
  color: white;
}

.pagination button:hover {
  background-color: #005181;
}

/* Style for the totals row */
.accounting-table tbody tr.totals-row {
  background-color: #073611c3;
  color: white; /* Optional: to make the text color white for better readability */
}

.accounting-table tbody tr.totals-row td {
  font-size: 1em; /* Adjust the size as needed */
  font-weight: bold; /* Optional: to make the text bold */
}

.modal,
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 800px;
  max-width: 100%;
}

.modal-header,
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
}

.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.refund-button {
  background-color: #b72222 !important; /* Red background */
  color: white; /* White text color */
  border: none; /* Remove border */
  padding: 3px 10px;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.refund-button:hover {
  background-color: #a53e3e !important; /* Darker red on hover */
}

.refund-row {
  background-color: #ffdbdb !important; /* Light red background for refunded rows */
}

.receipt-button {
  background-color: #2A4860; 
  padding: 3px 10px;
}

.acc-confirm-button {
  background-color: #8dc572 !important; 
  padding: 3px 10px;
}
.refund-red {
  color: rgb(241, 0, 0);
}
.refund-confirm-button {
  background-color: #bababa; 
  padding: 3px 10px;
}

.refund-watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg); 
  font-size: 8rem; 
  color: rgba(255, 0, 0, 0.3);
  z-index: 1;
  pointer-events: none; 
}

.modal-body {
  margin-bottom: 20px;
}

.modal-body div {
  margin-bottom: 15px;
}

.modal-body label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

.modal-body input,
.modal-body textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease;
}

.modal-body input:focus,
.modal-body textarea:focus {
  border-color: #0095da;
  outline: none;
}

.modal-body input::placeholder,
.modal-body textarea::placeholder {
  color: #999;
  font-style: italic;
}

.modal-body textarea {
  min-height: 100px;
  resize: vertical;
}
.register-date {
  align-items: right;
}

/* Responsive styles */
@media (max-width: 768px) {
  .accounting-container {
    padding: 10px;
    margin-left: 0;
  }

  .filter-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-container label,
  .filter-container input,
  .filter-container select {
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
  }

  .filter-button,
  .export-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .accounting-table th,
  .accounting-table td {
    font-size: 12px;
    padding: 5px;
  }

  .generate-button {
    padding: 5px;
  }

  .pagination button {
    padding: 5px;
    margin: 2px;
  }
  .export-button {
    padding: 5px 10px;
    background-color: #28a745;
    border-radius: 5px;
    color: white;
    border: none;
    width: 70%;
    text-decoration: none;
    cursor: pointer;
  }

  /* Card view for mobile */
  .accounting-table {
    display: none;
  }

  .accounting-cards {
    display: block;
  }

  .accounting-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 60px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .accounting-card div {
    margin-bottom: 5px;
  }

  .accounting-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .accounting-card-header .invoiceno {
    text-align: left;
  }
  
  .accounting-card-header .register-date {
    text-align: right;
  }

  .accounting-card-content {
    display: flex;
    flex-wrap: wrap;
  }

  .accounting-card-content > div {
    flex: 1 1 100%;
  }

  .accounting-card .buttons {
    display: flex;
    gap: 5px;
    align-items: center;
  }

}

@media (min-width: 769px) {
  .accounting-cards {
    display: none;
  }
}

@media (max-width: 480px) {
  .filter-container {
    align-items: center;
  }

  .filter-container label,
  .filter-container input,
  .filter-container select,
  .filter-button,
  .export-button {
    width: 100%;
  }

  .accounting-table th,
  .accounting-table td {
    font-size: 10px;
    padding: 3px;
  }

  .generate-button {
    padding: 3px;
  }

  .pagination button {
    padding: 3px;
    margin: 1px;
  }
  .export-button {
    padding: 5px 10px;
    background-color: #28a745;
    border-radius: 5px;
    color: white;
    border: none;
    width: 30%;
    text-decoration: none;
    cursor: pointer;
  }

  .filter-button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #337ab7;
    color: white;
    border: none;
    cursor: pointer;
    width: 40%;
}

  /* Mobile specific card view */
  .accounting-card-content > div {
    font-size: 12px;
  }

  .generate-button, .refund-button {
    padding: 3px 8px;
  }
}

/* INvoice print */
.invoice-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.invoice-modal {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  max-height: calc(100vh - 40px);
  border-radius: 8px;
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;
}

.invoice-section {
  margin-bottom: 20px;
}

.invoice-details {
  margin-top: 20px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
}

.invoice-info h3 {
  margin: 0;
  font-size: 24px;
}

.invoice-info p, .company-info p {
  margin: 5px 0;
}

.bill-to {
  margin-top: 20px;
}

.invoice-table {
  margin-top: 20px;
}

.invoice-table table {
  width: 100%;
  border-collapse: collapse;
}

.invoice-table th, .invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.payment-details, .signature {
  margin-top: 20px;
}

.signature img {
  max-width: 50px;
  height: auto;
}

.link-button2 {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
}

@media print {
  body * {
    visibility: hidden;
  }

  .invoice-modal,
  .invoice-modal * {
    visibility: visible;
  }
  .invoice-logo {
    height: 30px;
    width: 30px;
    margin-right: 20px;
}

  .invoice-modal-overlay {
    position: static;
    background-color: #fff;
    width: 100%;
    height: auto;
  }

  .invoice-modal {
    margin: 0;
    padding: 20px;
    width: 100%;
    max-width: none;
    max-height: none;
    border: none;
    box-shadow: none;
    overflow: visible;
    box-sizing: border-box;
    height: 100%;
    display: block;
    page-break-inside: avoid;
  }

  .invoice-details {
    margin: 0;
  }

  .invoice-header, .bill-to, .invoice-table, .payment-details, .signature {
    page-break-inside: avoid;
  }

  .modal-close-button,
  button {
    display: none;
  }

  .modal-close-button,
  button {
    display: none;
  }

  @page {
    size: A4;
    margin: 10mm;
  }

  .invoice-modal {
    page-break-before: auto; /* Ensure the modal content starts at the beginning of the first page */
  }
}
